@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900&display=swap');

body {
  margin: 0;
  font-family: "Archivo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth; /* Ativa rolagem suave */
}

/* Bloqueia o espaço em branco no topo e no final */
body, html {
  overflow-y: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

/* Snap-scroll nas seções */
.snap-y {
  scroll-snap-type: y proximity;
}

/* Alinha cada seção ao topo durante o scroll */
.snap-start {
  scroll-snap-align: start;
}

.image {
  background-image: url('./assets/Base fundo textura.png');
}

/* Configurações de fonte */
.font-archivo {
  font-family: "Archivo", sans-serif;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

.sidebutton {
  display: none; /* Oculta o botão por padrão */
  position: relative;
  left: 0;
  z-index: 99;
  width: 40px
}

.hide-sidebar {
  display: flex;
}

.textBackground {
  display: flex;
}

.ajust-title {
  display: flex;
  top: 10rem;
  left: 28%;
}

.event-date {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17%;
  bottom: 22rem;
  left: 28.5%;
  background-color: white;
  height: 2rem;
  border-radius: 8px;
}

.button-mobile {
  display: none;
}

.sub-title {
  display: flex;
  top: 23rem;
  left: 28%;
}

.ajust-fecomercio {
  bottom: 30%;
  left: 28%;
}

.ajust-woman {
  right: 12%;
  width: 30%,
}

.ajust-fing {
  right: 12%;
  width: 50%;
  bottom: -3rem;
  z-index: 2;
}

.ajust-man {
  height: 140rem;
  opacity: 0.8;
  right: 0; 
}

.ajust-event-text {
  z-index: 10; 
  color: white;
  width: 40%;
  margin-right: 2.5rem;
  margin-bottom: 5rem;
}

.text-title {
  font-size: 30px;
  font-weight: 800;
  margin-top: 0;
}

.text-event {
  font-size: 16px;
  font-weight: 400;
}

.loc-info {
  width: 25%;
  margin-bottom: 0;
}

.loc-info-card1 {
  height: 35%;
  padding: 2rem;
}

.text1 {
  margin-bottom: 1rem;
  font-size: 34px;
  color: #0F172A;
  font-weight: 600;
}

.text2 {
  font-size: 18px;
  color: #0F172A;
  font-weight: 500;
}

.text3 {
  margin-bottom: 0.5rem;
  font-size: 12px;
  color: #0F172A;
  font-weight: 300;
}

.card-loc-text1 {
  font-size: 22px;
  color: white;
  font-weight: 400;
}

.loc-info-map {
  width: 65%;
  height: 80%;
}

/* Efeito Hover para Cards dos Patrocinadores */
.sponsor-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.sponsor-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.sponsor-image {
  transition: opacity 0.3s ease;
}

.sponsor-card:hover .sponsor-image {
  opacity: 0.9;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16;
  display: flex;
  justify-content: start;
  z-index: 999;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.content-pc {
  display: flex;
}

.content-mobile {
  display: none !important;
}

/* USANDO PARA TELAS INFERIORES A 900px */
@media (max-width: 450px) and (max-height: 950px) {
  .content-pc {
    display: none !important;
  }

  .mobile-type {
    height: 95vh !important;
  }
  
  .content-mobile {
    display: flex !important;
  }
}

.bold {
  font-weight: 700  !important;
}

/* USANDO PARA TELAS INFERIORES A 600px */
@media (max-width: 400px) and (min-height: 750px) {
  .content-pc {
    display: none !important;
  }

  .mobile-type {
    height: 85vh !important;
  }
  
  .content-mobile {
    display: flex !important;
  }
}

/* USANDO PARA TELAS INFERIORES A 400px */
@media (max-width: 375px) and (max-height: 770px) {
  .text-title-sm {
    font-size: 22px;
  }

  .text-title-xsm {
    font-size: 14px;
  }

  .text-title-xsms {
    font-size: 12px;
  }
}


